import {useLocation} from 'react-router-dom';
import Permission from "./Permission";
import moment from "moment";
import {
    ACCESS_TOKEN,
    BRANCH,
    CURRENCIES,
    DATE_FORMAT,
    DATE_YEAR_FORMAT,
    GLOBAL_CONFIG,
    PRECISION_FACTOR,
    PRECISION_FACTOR_VALUE,
    TIME_FORMAT,
    TIME_FORMAT_HOUR,
    TIME_FORMAT_MIN,
    TIME_FORMAT_TWO
} from "./Constant";
import {RETRIEVE_IMAGE} from "../configs/APIUrl";
import dayjs from "dayjs";
import {parseInt} from "lodash";
import LocalStorageUtils from "./LocalStorageUtils";

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
}

export const getParams = (query, data) => {
    for (const key of Object.keys(data)) {

        let param = query.get(key);

        if (Array.isArray(data[key])) {

            if (param) {
                param = param.split(",");
            } else {
                param = [];
            }
        }

        data = {...data, [key]: param || ""}
    }
    return data;
}

export const isValueExistInSearch = (data) => {

    for (const key of Object.keys(data)) {
        if (data[key]) {
            return ["1"];
        }
    }
    return ["0"];
}

export const getBase64Image = (data) => {
    const base64 = btoa(
        new Uint8Array(data).reduce(
            (data, byte) => data + String.fromCharCode(byte),
            '',
        ),
    );

    return "data:;base64," + base64;
}

export const bindUrlWithParams = (url, params) => {
    let result = url;

    Object.keys(params).forEach(key => {
        if (!params[key] || params[key].length < 1) {
            delete params[key];
        }
    });

    Object.keys(params).forEach((key, index) => {

        let param = params[key];

        if (Array.isArray(params[key])) {
            param = param.toString();
        }

        if (index === 0) {
            result += `?${key}=${param}`;
        } else {
            result += `&${key}=${param}`;
        }

    });

    return result;
}

export const getFullPreviousRoute = (history) => {
    return `${history.location.pathname}${history.location.search}`;
}

export const resetState = (data) => {
    for (const key of Object.keys(data)) {
        data = {...data, [key]: ""}
    }
    return data;
}

export const getAllNonObjectValues = (data) => {

    let singleValue = {};

    for (const [key, value] of Object.entries(data)) {
        if (typeof value !== "object") {
            singleValue[key] = value;
        }
    }
    return singleValue;
}

export const getAllNestedObjectValues = (data) => {
    let singleValue = [];

    for (const value of Object.values(data)) {
        if (value && typeof value === "object") {
            singleValue.push(value);
        }
    }
    return singleValue
}

export const getPercentageValue = (value, percent) => {
    return (value * percent) / 100;
}

export const hasPermission = (loggedInUserPermissions, permissions) => {

    if (!permissions) {
        return false;
    }

    if (permissions.includes(Permission.ALL)) {
        return true;
    }

    if (!loggedInUserPermissions) {
        return false;
    }

    for (const permission of permissions) {

        if (loggedInUserPermissions.includes(permission)) {
            return true;
        }

    }

    return false;
}

export const apiUserAuth = () => {
    return {
        // Authorization: "Basic " + Buffer.from("shamim:aA12456").toString("base64")
        Authorization: "Basic dXNlcjp1c2Vy"
        // Authorization: "Basic " + btoa("shamim:aA12456")
    }
}

export const authorizationHeader = () => {
    const accessToken = LocalStorageUtils.getItem(ACCESS_TOKEN);
    return {"Authorization": `Bearer ${accessToken}`};
}

export const getErrorMessage = (error) => {
    if (error.response) {

        if (error.response.data) {
            return error.response.data.message || error.response.data.error;
        }

        return error.response.data;
    } else {
        return error.message;
    }
}

export const isRepresentativePresent = (value) => {

    if (!value) {
        return false;
    }

    const {rep_name, rep_mobile_code, rep_mobile, rep_email} = value;
    return !!(rep_name || rep_mobile_code || rep_mobile || rep_email);
}

export const checkUserType = (profile, type) => {
    return profile && profile.userType === type;
}

export const toDate = dateTime => {
    return moment(dateTime).format("MM-DD-YYYY")
}

export const longTodayDate = () => {
    return dayjs() * 1;
}

export const longTodayStartDate = (date) => {

    if (date) {
        return dayjs(date).startOf("day") * 1;
    }

    return dayjs().startOf("day") * 1;
}

export const longTodayEndDate = date => {

    if (date) {
        return dayjs(date).endOf("day") * 1;
    }

    return dayjs().endOf("day") * 1;
}

export const longFromDate = () => {

    const dayjs1 = dayjs(Date.now() - 30 * 24 * 3600 * 1000);

    return dayjs1 * 1;
}

export const longFirstOfDate = (dateType, date = null) => {

    let dayjs1 = null;

    if (date) {
        dayjs1 = dayjs(date).startOf(dateType)
    } else {
        dayjs1 = dayjs().startOf(dateType);
    }

    return dayjs1 * 1;
}

export const longEndOfDate = (dateType, date) => {

    let dayjs1 = null;

    if (date) {
        dayjs1 = dayjs(date).endOf(dateType)
    } else {
        dayjs1 = dayjs().endOf(dateType);
    }

    return dayjs1 * 1;
}

export const longEndDateOfMonth = () => {

    const dayjs1 = dayjs().endOf("month");

    return dayjs1 * 1;
}

export const parseDateToMoment = (date) => {
    return moment(moment(date).format("YYYY-MM-DD HH:mm:ss"))
}

export const longToDate = date => {
    if (!date) return;
    return new Date(date);
}

export const dateToLong = date => {
    if (!date) return;
    return date * 1;
}

export const longToDateFormatter = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format("MMM D, YYYY");
}

export const longToDateFormatterTwo = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(DATE_FORMAT);
}

export const longToDateFormatterYear = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(DATE_YEAR_FORMAT);
}


export const longToTimeFormatter = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(TIME_FORMAT_TWO);
}

export const longToDateTimeFormatter = date => {
    if (!date) return;
    return dayjs(longToDate(date)).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
}

export const phoneNumberConcat = (mobileCode, phoneNumber) => {
    return `${mobileCode}-${phoneNumber}`;
}


export const retrieveImageUrl = (path) => {

    if (!path) return null;

    return `${RETRIEVE_IMAGE}/${path}`;
};

export const phoneNumberFormat = (number) => {
    if (!number) return "";
    return `${number.slice(0, 2)}-${number.slice(2, 5)}-${number.slice(5, 8)}-${number.slice(8, number.length)}`;
}

export const phoneNumberInputFormat = (number) => {
    if (!number) return "";
    const newNumber = number.slice(2);
    return `${newNumber.slice(0, 3)}-${newNumber.slice(3, 6)}-${newNumber.slice(6, newNumber.length)}`;
}

export const phoneNumberValidator = (_, number, emptyAllowed) => {
    if (!number && emptyAllowed) return Promise.resolve();
    if (/(\d{3})-(\d{3})-(\d{4})/.test(number) && number?.length === 12) {
        return Promise.resolve();
    } else {
        return Promise.reject("Invalid Phone Number")
    }
}

export const phoneNumberPurifier = number => {
    if (!number) return;
    return number.replace(/-/g, "");
}

export const getI18DisplayData = (data, locale = "en-us") => {
    if (!data || data.length < 1) return "";

    const find = data.find(v => v.language.toLowerCase() === locale);

    if (find) {
        return find;
    } else {

        const find = data.find(v => v.language.toLowerCase() === "en-us");

        if (find) {
            return find
        }

        return "";
    }
}

export const getI18InputData = (data, locale = "en-us") => {

    if (!data || data.length < 1) return "";

    const find = data.find(v => v.language.toLowerCase() === locale);

    if (find) {
        return find;
    }

    return "";
}

export const getBranchId = () => JSON.parse(LocalStorageUtils.getItem(BRANCH)).id;

export const priceFormatter = (price = 0) => {
    return `${getCurrency()} ${precisionFactor(price)}`;
}

export const precisionFactor = price => {

    if (!price) {
        return 0
    }

    let globalConfig = LocalStorageUtils.getItem(GLOBAL_CONFIG);
    globalConfig = globalConfig && globalConfig !== "null" ? JSON.parse(globalConfig) : null;

    const precisionAfterDecimal = globalConfig && globalConfig.precisionAfterDecimal ? globalConfig.precisionAfterDecimal : 0;

    return parseInt(price * PRECISION_FACTOR[precisionAfterDecimal]) / PRECISION_FACTOR[precisionAfterDecimal];
}

export const getPrecisionDigit = () => {

    let globalConfig = LocalStorageUtils.getItem(GLOBAL_CONFIG);
    globalConfig = globalConfig && globalConfig !== "null" ? JSON.parse(globalConfig) : null;

    return globalConfig && globalConfig.precisionAfterDecimal ? PRECISION_FACTOR_VALUE[globalConfig.precisionAfterDecimal] : 0;

}

export const fourDigitsPrecisionFactor = price => {

    if (!price) {
        return 0
    }

    return `${getCurrency()}${parseInt(price * 10000) / 10000}`;
}

export const stockSummaryPrecisionFactor = (value, precisionValue = 10000) => {

    if (!value) {
        return 0;
    }

    return parseInt(value * precisionValue) / precisionValue;
}

export const getCurrency = () => {

    const _currency = LocalStorageUtils.getItem(GLOBAL_CONFIG);
    const currency = _currency && _currency !== "null" ? JSON.parse(_currency).currency : "USD";

    return CURRENCIES[currency]
}

export const numberToDateTime = number => {

    const hours = number / 100;
    const minutes = number % 100;

    const date = new Date();

    date.setHours(hours, minutes);

    return date;
}

export const enumFormatter = value => {

    if (!value) return "";

    return value.replaceAll("_", " ");
}

export const enumToCapitalize = value => {
    if (!value) return "";
    const splitWord = value.split("_");
    return splitWord.map(word => word[0].toUpperCase() + word.substring(1).toLowerCase()).join(" ");
}

export const getPercentage = (total, percentValue) => {

    if (total === 0) return 0.00;

    return ((percentValue / total) * 100).toFixed(2);
}

export const getRangeReportName = (title, from, to, format = DATE_FORMAT) => {
    return `${dayjs(from).format(format)}_TO_${dayjs(to).format(format)}_${title}`;
}

export const getDateReportName = (title, date, format = DATE_FORMAT) => {
    return `${dayjs(date).format(format)}_${title}`;
}

export const getSheetColumns = (sheet, columns) => {

    sheet.getRow(1).fill = {
        fgColor: {argb: "#000000"}
    }

    sheet.getRow(1).font = {
        name: "'Barlow Condensed', 'Inter', 'Almarai', 'Noto Sans Bengali', sans-serif",
        bold: true
    }

    sheet.columns = columns;

}

export const excelWriteBuffer = (workbook, title, from, to) => {

    workbook.xlsx.writeBuffer().then(data => {

        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet"
        });

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${getRangeReportName(title, from, to)}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    })

}

export const excelDateWriteBuffer = (workbook, title, date) => {

    workbook.xlsx.writeBuffer().then(data => {

        const blob = new Blob([data], {
            type: "application/vnd.openxmlformats-officedocument.spreadsheet.sheet"
        });

        const url = window.URL.createObjectURL(blob);
        const anchor = document.createElement("a");
        anchor.href = url;
        anchor.download = `${getDateReportName(title, date)}.xlsx`;
        anchor.click();
        window.URL.revokeObjectURL(url);
    })

}

export const getDataFromNestedObject = (object, dataIndex) => {

    if (!dataIndex) return null;

    let split = dataIndex.split(".");
    let value = object;

    for (let i = 0; i < split.length; i++) {
        value = value[split[i]];
    }

    return value;
}

export const reportDateTimeFormat = () =>
    dayjs(new Date()).format("MMMM D, YYYY h:mm A");

export const reportDateFormat = () =>
    dayjs(new Date()).format("MMMM D, YYYY");

export const reportTimeFormat = () =>
    dayjs(new Date()).format("h:mm A");

export const getDiscountAmount = (price, discount, discountType) => {

    if (!discount) {
        return 0;
    }

    if (discountType === "PERCENTAGE") {
        return (price * discount) / 100;
    } else {
        return discount;
    }
}

export const percentValueCalculation = (price, discount) => {

    if (discount === 0 || !discount) {
        return 0
    }

    return (price * discount) / 100;
}

export const timeToInteger = (time) => {

    const hour = dayjs(time).format(TIME_FORMAT_HOUR);
    const minute = dayjs(time).format(TIME_FORMAT_MIN);

    return parseInt(hour + minute);

}

export const convertIntegerTimeToDateTime = time => {

    const hours = time / 100;
    const minutes = time % 100;

    const date = new Date(0, 0, 0, hours, minutes);

    return dayjs(date).format(`${DATE_FORMAT} ${TIME_FORMAT}`);
}

export const convertIntegerTimeToTime = time => {

    const hours = time / 100;
    const minutes = time % 100;

    const date = new Date(0, 0, 0, hours, minutes);

    return dayjs(date).format(`${TIME_FORMAT_TWO}`);
}

export const DialingCodes = require("../json/DialingCodes.json");

export const getDialingNumberByCountryCode = countryCode => {
    return DialingCodes.find(dialingCode => dialingCode.code.toUpperCase() === countryCode.toUpperCase())
}

export const getPhoneLengthByCountryCode = countryCode => {

    const find = DialingCodes.find(dialingCode => dialingCode.code.toUpperCase() === countryCode.toUpperCase());

    if (find) {
        return find.phoneLength
    }

    return 0;
}

export const collapsedRoutes = new Set(
    [
        "create-catering-menu",
        "create-branch-catering-order",
        "update-branch-catering-order",
        "update-catering-menu",
        "create-global-catering-menu",
        "update-branch-catering-menu",
        "update-global-catering-menu",
        "table-list",
        "create-ready_product_stock",
        "update-ready_product_stock",
        "create-ready_product_stock_out"
    ]
)

export const priceFixedDigits = (price, digits) => {

    if (!price) {
        return 0.0
    }
    return price.toFixed(digits)
}

export const getPercentDiscountAmount = (price, discountPercent) => {

    let discountAmount = 0;

    if (!price) {
        return 0.0
    }

    if (discountPercent > 0) {
        discountAmount = (price - ((price * discountPercent) / 100));
    } else {
        discountAmount = price;
    }


    return discountAmount;

}

export const listAnyValueIncludesInString = (str, list) => {
    let result = false;
    list.forEach(item => {
        console.log(str, item)
        if (str.includes(item)) {
            result = true;
        }
    })
    return result;
}

export const playAudio = (audio) => {
    audio?.play().then();
}

export const pauseAudio = (audio) => {
    audio?.pause();
    audio.currentTime = 0; // Reset audio to beginning
}

export const defaultCountry = {
    "code": "BD",
    "label": "Bangladesh",
    "phone": "+880",
    "phoneLength": 10,
    "emoji": "🇧🇩"
}

