export const ROOT_PATH = "/";

export const LOGIN_PATH = `${ROOT_PATH}login`;
export const OWNER_LOGIN_PATH = `${ROOT_PATH}owner-login`;
export const DASHBOARD_PATH = `${ROOT_PATH}dashboard`;
export const RB_DASHBOARD_PATH = `${ROOT_PATH}rb-dashboard`;

export const PAGE_404_PATH = `${ROOT_PATH}404`;
export const PAGE_403_PATH = `${ROOT_PATH}403`;
export const PAGE_500_PATH = `${ROOT_PATH}500`;

// user
export const USER_LIST_VIEW_PATH = `${ROOT_PATH}user-list`;
export const CREATE_USER_PATH = `${ROOT_PATH}create-user`;
export const UPDATE_USER_PATH = `${ROOT_PATH}update-user`;
export const PROFILE_PATH = `${ROOT_PATH}profile`;

// franchise user
export const CREATE_FRANCHISE_USER_PATH = `${ROOT_PATH}create-franchise-user`;
export const FRANCHISE_USER_LIST_VIEW_PATH = `${ROOT_PATH}franchise-user-list`;
export const UPDATE_FRANCHISE_USER_PATH = `${ROOT_PATH}update-franchise-user`;

// product
export const PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}product-list`;
export const CREATE_PRODUCT_PATH = `${ROOT_PATH}create-product`;
export const UPDATE_PRODUCT_PATH = `${ROOT_PATH}update-product`;

// Product
export const RB_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}rb-product-list`;
export const RB_CREATE_PRODUCT_PATH = `${ROOT_PATH}rb-create-product`;
export const RB_UPDATE_PRODUCT_PATH = `${ROOT_PATH}rb-update-product`;

// requested product
export const REQUESTED_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}requested-product-list`;

// branch request product
export const CREATE_BRANCH_REQUEST_PRODUCT_PATH = `${ROOT_PATH}create-branch-request-product`;
export const BRANCH_REQUEST_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}branch-request-product-list`;
export const UPDATE_BRANCH_REQUEST_PRODUCT_PATH = `${ROOT_PATH}update-branch-request-product`;

// product category
export const PRODUCT_CATEGORY_LIST_VIEW_PATH = `${ROOT_PATH}category-list`;
export const RB_PRODUCT_CATEGORY_LIST_VIEW_PATH = `${ROOT_PATH}rb-category-list`;
export const CREATE_PRODUCT_CATEGORY_PATH = `${ROOT_PATH}create-category`;
export const UPDATE_PRODUCT_CATEGORY_PATH = `${ROOT_PATH}update-category`;

// product add-ons
export const PRODUCT_ADD_ONS_LIST_VIEW_PATH = `${ROOT_PATH}add-ons-list`;
export const CREATE_PRODUCT_ADD_ONS_PATH = `${ROOT_PATH}create-add-ons`;
export const UPDATE_PRODUCT_ADD_ONS_PATH = `${ROOT_PATH}update-add-ons`;

// product add-ons
export const RB_PRODUCT_ADD_ONS_LIST_VIEW_PATH = `${ROOT_PATH}rb-add-ons-list`;
export const RB_CREATE_PRODUCT_ADD_ONS_PATH = `${ROOT_PATH}rb-create-add-ons`;
export const RB_UPDATE_PRODUCT_ADD_ONS_PATH = `${ROOT_PATH}rb-update-add-ons`;


// requested add-ons list
export const REQUESTED_ADD_ONS_LIST_VIEW_PATH = `${ROOT_PATH}requested-add-ons-list`;

// branch add-ons list
export const BRANCH_ADD_ONS_LIST_VIEW_PATH = `${ROOT_PATH}branch-add-ons-list`;


// branch request add-ons list
export const CREATE_BRANCH_REQUEST_ADD_ONS_PATH = `${ROOT_PATH}create-branch-request-add-ons`;
export const BRANCH_REQUEST_ADD_ONS_LIST_VIEW_PATH = `${ROOT_PATH}branch-request-add-ons-list`;
export const UPDATE_BRANCH_REQUEST_ADD_ONS_PATH = `${ROOT_PATH}update-branch-request-add-ons`;

// branch
export const BRANCH_LIST_VIEW_PATH = `${ROOT_PATH}branch-list`;
export const CREATE_BRANCH_PATH = `${ROOT_PATH}crate-branch`;
export const UPDATE_BRANCH_PATH = `${ROOT_PATH}update-branch`;
export const BRANCH_DETAILS_VIEW_PATH = `${ROOT_PATH}branch-details`;

// franchise
export const FRANCHISE_LIST_VIEW_PATH = `${ROOT_PATH}franchise-list`;
export const CREATE_FRANCHISE_PATH = `${ROOT_PATH}crate-franchise`;
export const UPDATE_FRANCHISE_PATH = `${ROOT_PATH}update-franchise`;
export const FRANCHISE_DETAILS_VIEW_PATH = `${ROOT_PATH}franchise-details`;
export const FRANCHISE_DASHBOARD_VIEW_PATH = `${ROOT_PATH}franchise-dashboard`;

// assign employee
export const ASSIGN_EMPLOYEE_PATH = `${ROOT_PATH}assign-employee`;

// order
export const ORDER_LIST_VIEW_PATH = `${ROOT_PATH}order-list`;
export const ORDER_DETAILS_VIEW_PATH = `${ROOT_PATH}order-details`;
export const ORDER_HISTORY_VIEW_PATH = `${ROOT_PATH}order-history`;
export const ORDER_REPORTS_PATH = `${ROOT_PATH}order-reports`;
export const ORDER_ITEM_REPORTS_PATH = `${ROOT_PATH}order-item-reports`;

// branch order
export const BRANCH_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}branch-order-list`;
export const BRANCH_ORDER_DETAILS_VIEW_PATH = `${ROOT_PATH}branch-order-details`;
export const BRANCH_ORDER_REPORT_VIEW_PATH = `${ROOT_PATH}branch-order-report`;
export const BRANCH_ORDER_ITEM_REPORT_VIEW_PATH = `${ROOT_PATH}branch-order-item-report`;
export const CREATE_ORDER_PATH = `${ROOT_PATH}create-order`;
export const UPDATE_ORDER_PATH = `${ROOT_PATH}update-order`;
export const BRANCH_ORDER_HISTORY_PATH = `${ROOT_PATH}branch-order-history`;

// branch product
export const BRANCH_PRODUCT_LIST_VIEW = `${ROOT_PATH}branch-product-list`

// role
export const ROLE_LIST_VIEW_PATH = `${ROOT_PATH}role-list`;
export const CREATE_ROLE_PATH = `${ROOT_PATH}create-role`;
export const UPDATE_ROLE_PATH = `${ROOT_PATH}update-role`;


// promo
export const PROMO_LIST_VIEW_PATH = `${ROOT_PATH}promo-list`;
export const CREATE_PROMO_PATH = `${ROOT_PATH}create-promo`;
export const UPDATE_PROMO_PATH = `${ROOT_PATH}update-promo`;

// global config
export const GLOBAL_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}global-config-list`;
export const CREATE_GLOBAL_CONFIG_PATH = `${ROOT_PATH}create-global-config`;
export const UPDATE_GLOBAL_CONFIG_PATH = `${ROOT_PATH}update-global-config`;

// customer
export const CUSTOMER_LIST_VIEW_PATH = `${ROOT_PATH}customer-list`;

// banner
export const BANNER_LIST_VIEW_PATH = `${ROOT_PATH}banner-list`;
export const CREATE_BANNER_PATH = `${ROOT_PATH}create-banner`;
export const UPDATE_BANNER_PATH = `${ROOT_PATH}update-banner`;

// analytics
export const ANALYTICS_VIEW_PATH = `${ROOT_PATH}analytics`;

// analytics
export const RB_ANALYTICS_VIEW_PATH = `${ROOT_PATH}rb-analytics`;

// table
export const TABLE_LIST_VIEW_PATH = `${ROOT_PATH}table-list`;
export const CREATE_TABLE_PATH = `${ROOT_PATH}create-table`;
export const UPDATE_TABLE_PATH = `${ROOT_PATH}update-table`;

// catering section
export const CATERING_SECTION_LIST_VIEW_PATH = `${ROOT_PATH}catering-section-list`;
export const RB_CATERING_SECTION_LIST_VIEW_PATH = `${ROOT_PATH}rb-catering-section-list`;
export const CREATE_CATERING_SECTION_PATH = `${ROOT_PATH}create-catering-section`;
export const UPDATE_CATERING_SECTION_PATH = `${ROOT_PATH}update-catering-section`;

// catering product
export const CATERING_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}catering-product-list`;
export const CREATE_CATERING_PRODUCT_PATH = `${ROOT_PATH}create-catering-product`;
export const UPDATE_CATERING_PRODUCT_PATH = `${ROOT_PATH}update-catering-product`;

// catering product
export const RB_CATERING_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}rb-catering-product-list`;
export const RB_CREATE_CATERING_PRODUCT_PATH = `${ROOT_PATH}rb-create-catering-product`;
export const RB_UPDATE_CATERING_PRODUCT_PATH = `${ROOT_PATH}rb-update-catering-product`;

// requested catering product
export const REQUESTED_CATERING_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}requested-catering-product-list`;

// catering menu
export const CATERING_MENU_LIST_VIEW_PATH = `${ROOT_PATH}catering-menu-list`;
export const CREATE_CATERING_MENU_PATH = `${ROOT_PATH}create-catering-menu`;
export const UPDATE_CATERING_MENU_PATH = `${ROOT_PATH}update-catering-menu`;
export const CATERING_MENU_DETAILS_PATH = `${ROOT_PATH}catering-menu-details`;

// catering menu
export const RB_CATERING_MENU_LIST_VIEW_PATH = `${ROOT_PATH}rb-catering-menu-list`;
export const RB_CREATE_CATERING_MENU_PATH = `${ROOT_PATH}rb-create-catering-menu`;
export const RB_UPDATE_CATERING_MENU_PATH = `${ROOT_PATH}rb-update-catering-menu`;
export const RB_CATERING_MENU_DETAILS_PATH = `${ROOT_PATH}rb-catering-menu-details`;

// requested catering menu
export const REQUESTED_CATERING_MENU_LIST_VIEW_PATH = `${ROOT_PATH}requested-catering-menu-list`

// global catering menu -> branch
export const GLOBAL_CATERING_MENU_LIST_VIEW_PATH = `${ROOT_PATH}global-catering-menu-list`;
export const CREATE_GLOBAL_CATERING_MENU_PATH = `${ROOT_PATH}create-global-catering-menu`;
export const UPDATE_GLOBAL_CATERING_MENU_PATH = `${ROOT_PATH}update-global-catering-menu`;
export const GLOBAL_CATERING_MENU_DETAILS_PATH = `${ROOT_PATH}global-catering-menu-details`;

// branch catering menu -> branch
export const BRANCH_CATERING_MENU_LIST_VIEW_PATH = `${ROOT_PATH}branch-catering-menu-list`;
export const UPDATE_BRANCH_CATERING_MENU_PATH = `${ROOT_PATH}update-branch-catering-menu`;
export const BRANCH_CATERING_MENU_DETAILS_PATH = `${ROOT_PATH}branch-catering-menu-details`;

// branch catering order
export const BRANCH_CATERING_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}branch-catering-order-list`;
export const CREATE_BRANCH_CATERING_ORDER_PATH = `${ROOT_PATH}create-branch-catering-order`;
export const UPDATE_BRANCH_CATERING_ORDER_PATH = `${ROOT_PATH}update-branch-catering-order`;

// branch catering product
export const BRANCH_CATERING_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}branch-catering-product-list`;

// branch request catering product
export const RB_GLOBAL_PRODUCT_LIST_VIEW_PATH = `${ROOT_PATH}rb-global-product-list`;
export const UPDATE_RB_GLOBAL_PRODUCT_PATH = `${ROOT_PATH}update-rb-global-product`;
export const CREATE_RB_GLOBAL_PRODUCT_PATH = `${ROOT_PATH}create-rb-global-product`;

export const KITCHEN_DISPLAY_PATH = `${ROOT_PATH}kitchen-display`;

// branch employee list
export const BRANCH_EMPLOYEE_LIST_PATH = `${ROOT_PATH}branch-employee-list`;

//pos
export const POS_VIEW_PATH = `${ROOT_PATH}pos-view`;
export const CREATE_POS_ORDER_PATH = `${ROOT_PATH}create-pos-order`;
export const UPDATE_POS_ORDER_PATH = `${ROOT_PATH}update-pos-order`;
export const POS_ORDER_LIST_VIEW_PATH = `${ROOT_PATH}pos-order-list-view`;
export const POS_HOLD_LIST_VIEW_PATH = `${ROOT_PATH}pos-hold-list-view`;

//global_ingredient management
export const INGREDIENT_CATEGORY_LIST = `${ROOT_PATH}ingredient-category-list`;
export const RB_INGREDIENT_CATEGORY_LIST = `${ROOT_PATH}rb-ingredient-category-list`;
export const INGREDIENT_CATEGORY_CREATE = `${ROOT_PATH}create-ingredient-category`;
export const INGREDIENT_CATEGORY_UPDATE = `${ROOT_PATH}update-ingredient-category`;
export const INGREDIENT_LIST = `${ROOT_PATH}ingredient-list`;
export const INGREDIENT_CREATE = `${ROOT_PATH}create-ingredient`;
export const INGREDIENT_UPDATE = `${ROOT_PATH}update-ingredient`;

// Ingredient
export const RB_INGREDIENT_LIST = `${ROOT_PATH}rb-ingredient-list`;
export const RB_INGREDIENT_CREATE = `${ROOT_PATH}rb-create-ingredient`;
export const RB_INGREDIENT_UPDATE = `${ROOT_PATH}rb-update-ingredient`;

export const BRANCH_GLOBAL_INGREDIENT_LIST = `${ROOT_PATH}branch-global-ingredient-list`;
export const BRANCH_INGREDIENT_CREATE = `${ROOT_PATH}create-branch-ingredient`;
export const BRANCH_INGREDIENT_UPDATE = `${ROOT_PATH}update-branch-ingredient`;
export const REQUESTED_INGREDIENT_LIST = `${ROOT_PATH}requested-ingredient-list`;
export const BRANCH_INGREDIENT_LIST = `${ROOT_PATH}branch-ingredient-list`;
export const SUPPLIER_LIST = `${ROOT_PATH}supplier-list`;
export const BRANCH_SUPPLIER_LIST = `${ROOT_PATH}branch-supplier-list`;
export const CREATE_BRANCH_SUPPLIER = `${ROOT_PATH}create-branch-supplier`;
export const UPDATE_BRANCH_SUPPLIER = `${ROOT_PATH}update-branch-supplier`;

// product_stock
export const STOCK_LIST_VIEW_PATH = `${ROOT_PATH}stock-list`;
export const CREATE_STOCK_PATH = `${ROOT_PATH}create-stock`;
export const UPDATE_STOCK_PATH = `${ROOT_PATH}update-stock`;
export const STOCK_DETAILS_PATH = `${ROOT_PATH}stock-details`;

// ready_product_stock
export const READY_PRODUCT_STOCK_LIST_VIEW_PATH = `${ROOT_PATH}ready-product-stock-list`;
export const READY_PRODUCT_CREATE_STOCK_PATH = `${ROOT_PATH}ready-product-create-stock`;
export const READY_PRODUCT_UPDATE_STOCK_PATH = `${ROOT_PATH}ready-product-update-stock`;
export const READY_PRODUCT_STOCK_DETAILS_PATH = `${ROOT_PATH}ready-product-stock-details`;
export const READY_PRODUCT_LIST_PATH = `${ROOT_PATH}ready-product-list`;


// ready_product_stock out
export const READY_PRODUCT_STOCK_OUT_LIST_VIEW_PATH = `${ROOT_PATH}ready-product-stock-out-list`;
export const READY_PRODUCT_CREATE_STOCK_OUT_PATH = `${ROOT_PATH}ready-product-create-stock-out`;
export const READY_PRODUCT_UPDATE_STOCK_OUT_PATH = `${ROOT_PATH}ready-product-update-stock-out`;
export const READY_PRODUCT_STOCK_OUT_DETAILS_PATH = `${ROOT_PATH}ready-product-stock-out-details`;

// agent
export const AGENT_LIST_VIEW_PATH = `${ROOT_PATH}agent-list`;

// branch config
export const BRANCH_CONFIG_LIST_VIEW_PATH = `${ROOT_PATH}branch-config-list`;
export const CREATE_BRANCH_CONFIG_PATH = `${ROOT_PATH}create-branch-config`;
export const UPDATE_BRANCH_CONFIG_PATH = `${ROOT_PATH}update-branch-config`;

// stock out
export const STOCK_OUT_LIST_VIEW_PATH = `${ROOT_PATH}stock-out-list`;
export const CREATE_STOCK_OUT_PATH = `${ROOT_PATH}create-stock-out`;
export const UPDATE_STOCK_OUT_PATH = `${ROOT_PATH}update-stock-out`;
export const STOCK_OUT_DETAILS_PATH = `${ROOT_PATH}stock-out-details`;

// agent payment
export const AGENT_PAYMENT_LIST_VIEW_PATH = `${ROOT_PATH}agent-payment-list`;
export const CREATE_AGENT_PAYMENT_PATH = `${ROOT_PATH}create-agent-payment`;
export const UPDATE_AGENT_PAYMENT_PATH = `${ROOT_PATH}update-agent-payment`;

// ready_product_stock summary
export const STOCK_SUMMARY_LIST_VEW_PATH = `${ROOT_PATH}stock-summary-list`;
export const STOCK_SUMMARY_DETAILS_PATH = `${ROOT_PATH}stock-summary-details`;

// expired stock
export const EXPIRED_STOCK_LIST_VIEW_PATH = `${ROOT_PATH}expired-stock-list`;

// expired ready_product_stock
export const READY_PRODUCT_EXPIRED_STOCK_LIST_VIEW_PATH = `${ROOT_PATH}ready-product-expired-stock-list`;

export const QR_CODE_PATH = `${ROOT_PATH}qr-code`;
export const OUR_MENU_PATH = `${ROOT_PATH}our-menu`;

export const PUBLIC_BANNERS_PATH = `${ROOT_PATH}banners`
export const PUBLIC_BANNER_DETAILS_PATH = `${ROOT_PATH}banner-details`