const Permission = {
    ALL: "ALL",
    READ_USER: "READ_USER",
    CREATE_USER: "CREATE_USER",
    MODIFY_USER: "MODIFY_USER",
    DELETE_USER: "DELETE_USER",
    READ_USER_PROFILE: "READ_USER_PROFILE",
    MODIFY_USER_PROFILE: "MODIFY_USER_PROFILE",
    UPDATE_USER_PASSWORD: "UPDATE_USER_PASSWORD",
    UPDATE_USER_PROFILE_PASSWORD: "UPDATE_USER_PROFILE_PASSWORD",
    READ_CUSTOMER: "READ_CUSTOMER",
    CREATE_CUSTOMER: "CREATE_CUSTOMER",
    MODIFY_CUSTOMER: "MODIFY_CUSTOMER",
    DELETE_CUSTOMER: "DELETE_CUSTOMER",
    READ_CUSTOMER_PROFILE: "READ_CUSTOMER_PROFILE",
    MODIFY_CUSTOMER_PROFILE: "MODIFY_CUSTOMER_PROFILE",
    UPDATE_CUSTOMER_PASSWORD: "UPDATE_CUSTOMER_PASSWORD",
    UPDATE_CUSTOMER_PROFILE_PASSWORD: "UPDATE_CUSTOMER_PROFILE_PASSWORD",
    READ_ROLE: "READ_ROLE",
    CREATE_ROLE: "CREATE_ROLE",
    MODIFY_ROLE: "MODIFY_ROLE",
    DELETE_ROLE: "DELETE_ROLE",
    READ_PERMISSION: "READ_PERMISSION",
    READ_ADD_ON: "READ_ADD_ON",
    CREATE_ADD_ON: "CREATE_ADD_ON",
    MODIFY_ADD_ON: "MODIFY_ADD_ON",
    DELETE_ADD_ON: "DELETE_ADD_ON",
    READ_REQUESTED_ADD_ON: "READ_REQUESTED_ADD_ON",
    READ_GLOBAL_ADD_ON_FOR_BRANCH: "READ_GLOBAL_ADD_ON_FOR_BRANCH",
    CREATE_GLOBAL_ADD_ON_FOR_BRANCH: "CREATE_GLOBAL_ADD_ON_FOR_BRANCH",
    MODIFY_GLOBAL_ADD_ON_FOR_BRANCH: "MODIFY_GLOBAL_ADD_ON_FOR_BRANCH",
    READ_BRANCH_ADD_ON: "READ_BRANCH_ADD_ON",
    CREATE_BRANCH_ADD_ON: "CREATE_BRANCH_ADD_ON",
    MODIFY_BRANCH_ADD_ON: "MODIFY_BRANCH_ADD_ON",
    DELETE_BRANCH_ADD_ON: "DELETE_BRANCH_ADD_ON",
    READ_PERSONAL_ADDRESS: "READ_PERSONAL_ADDRESS",
    CREATE_PERSONAL_ADDRESS: "CREATE_PERSONAL_ADDRESS",
    MODIFY_PERSONAL_ADDRESS: "MODIFY_PERSONAL_ADDRESS",
    DELETE_PERSONAL_ADDRESS: "DELETE_PERSONAL_ADDRESS",
    READ_BRANCH: "READ_BRANCH",
    CREATE_BRANCH: "CREATE_BRANCH",
    MODIFY_BRANCH: "MODIFY_BRANCH",
    DELETE_BRANCH: "DELETE_BRANCH",
    ASSIGN_EMPLOYEE_TO_BRANCH: "ASSIGN_EMPLOYEE_TO_BRANCH",
    READ_PRODUCT: "READ_PRODUCT",
    READ_REQUESTED_PRODUCT: "READ_REQUESTED_PRODUCT",
    CREATE_PRODUCT: "CREATE_PRODUCT",
    MODIFY_PRODUCT: "MODIFY_PRODUCT",
    DELETE_PRODUCT: "DELETE_PRODUCT",
    READ_GLOBAL_PRODUCT_FOR_BRANCH: "READ_GLOBAL_PRODUCT_FOR_BRANCH",
    CREATE_GLOBAL_PRODUCT_FOR_BRANCH: "CREATE_GLOBAL_PRODUCT_FOR_BRANCH",
    MODIFY_GLOBAL_PRODUCT_FOR_BRANCH: "MODIFY_GLOBAL_PRODUCT_FOR_BRANCH",
    READ_BRANCH_PRODUCT: "READ_BRANCH_PRODUCT",
    CREATE_BRANCH_PRODUCT: "CREATE_BRANCH_PRODUCT",
    MODIFY_BRANCH_PRODUCT: "MODIFY_BRANCH_PRODUCT",
    DELETE_BRANCH_PRODUCT: "DELETE_BRANCH_PRODUCT",
    READ_CATEGORY: "READ_CATEGORY",
    CREATE_CATEGORY: "CREATE_CATEGORY",
    MODIFY_CATEGORY: "MODIFY_CATEGORY",
    DELETE_CATEGORY: "DELETE_CATEGORY",
    READ_PROMO: "READ_PROMO",
    CREATE_PROMO: "CREATE_PROMO",
    MODIFY_PROMO: "MODIFY_PROMO",
    DELETE_PROMO: "DELETE_PROMO",
    READ_BRANCH_CATEGORY: "READ_BRANCH_CATEGORY",
    IMAGE_UPLOAD: "IMAGE_UPLOAD",
    READ_GLOBAL_CONFIG: "READ_GLOBAL_CONFIG",
    CREATE_GLOBAL_CONFIG: "CREATE_GLOBAL_CONFIG",
    MODIFY_GLOBAL_CONFIG: "MODIFY_GLOBAL_CONFIG",
    READ_ORDER: "READ_ORDER",
    READ_BRANCH_ORDER: "READ_BRANCH_ORDER",
    BRANCH_ORDER_MENU: "BRANCH_ORDER_MENU",
    MODIFY_BRANCH_ORDER: "MODIFY_BRANCH_ORDER",
    READ_BANNER: "READ_BANNER",
    MODIFY_BANNER: "MODIFY_BANNER",
    CREATE_BANNER: "CREATE_BANNER",
    READ_TOTAL_COUNTS: "READ_TOTAL_COUNTS",
    READ_BRANCH_EARNINGS: "READ_BRANCH_EARNINGS",
    READ_BRANCH_EARNINGS_GRAPH: "READ_BRANCH_EARNINGS_GRAPH",
    READ_BRANCH_ORDERS: "READ_BRANCH_ORDERS",
    CREATE_BRANCH_ORDER: "CREATE_BRANCH_ORDER",
    READ_BRANCH_ORDERS_GRAPH: "READ_BRANCH_ORDERS_GRAPH",
    READ_ORDER_COUNT_BY_TYPE: "READ_ORDER_COUNT_BY_TYPE",
    READ_ORDER_COUNT_BY_STATUS: "READ_ORDER_COUNT_BY_STATUS",
    READ_TOP_BRANCHES: "READ_TOP_BRANCHES",
    READ_TOP_PRODUCTS: "READ_TOP_PRODUCTS",
    READ_CUSTOMER_COUNT: "READ_CUSTOMER_COUNT",
    READ_PRODUCT_REVIEW: "READ_PRODUCT_REVIEW",
    READ_CUSTOMER_REVIEW: "READ_CUSTOMER_REVIEW",
    READ_TOTAL_COUNT_FOR_BRANCH: "READ_TOTAL_COUNT_FOR_BRANCH",
    READ_ORDERS_GRAPH_FOR_BRANCH: "READ_ORDERS_GRAPH_FOR_BRANCH",
    READ_ORDER_COUNT_FOR_BRANCH: "READ_ORDER_COUNT_FOR_BRANCH",
    READ_TOP_PRODUCTS_FOR_BRANCH: "READ_TOP_PRODUCTS_FOR_BRANCH",
    READ_CUSTOMER_REVIEW_FOR_BRANCH: "READ_CUSTOMER_REVIEW_FOR_BRANCH",
    READ_ORDER_LIST_FOR_BRANCH: "READ_ORDER_LIST_FOR_BRANCH",
    READ_BRANCH_TABLE: "READ_BRANCH_TABLE",
    CREATE_BRANCH_TABLE: "CREATE_BRANCH_TABLE",
    MODIFY_BRANCH_TABLE: "MODIFY_BRANCH_TABLE",
    DELETE_BRANCH_TABLE: "DELETE_BRANCH_TABLE",
    READ_CATERING_MENU: "READ_CATERING_MENU",
    READ_REQUESTED_CATERING_MENU: "READ_REQUESTED_CATERING_MENU",
    CREATE_CATERING_MENU: "CREATE_CATERING_MENU",
    MODIFY_CATERING_MENU: "MODIFY_CATERING_MENU",
    CREATE_GLOBAL_CATERING_MENU_FOR_BRANCH: "CREATE_GLOBAL_CATERING_MENU_FOR_BRANCH",
    READ_GLOBAL_CATERING_MENU_FOR_BRANCH: "READ_GLOBAL_CATERING_MENU_FOR_BRANCH",
    MODIFY_GLOBAL_CATERING_MENU_FOR_BRANCH: "MODIFY_GLOBAL_CATERING_MENU_FOR_BRANCH",
    READ_CATERING_SECTION: "READ_CATERING_SECTION",
    CREATE_CATERING_SECTION: "CREATE_CATERING_SECTION",
    MODIFY_CATERING_SECTION: "MODIFY_CATERING_SECTION",
    DELETE_CATERING_SECTION: "DELETE_CATERING_SECTION",
    READ_GLOBAL_CATERING_SECTION_FOR_BRANCH: "READ_GLOBAL_CATERING_SECTION_FOR_BRANCH",
    READ_BRANCH_CATERING_MENU: "READ_BRANCH_CATERING_MENU",
    CREATE_BRANCH_CATERING_MENU: "CREATE_BRANCH_CATERING_MENU",
    MODIFY_BRANCH_CATERING_MENU: "MODIFY_BRANCH_CATERING_MENU",
    READ_BRANCH_EMPLOYEE: "READ_BRANCH_EMPLOYEE",
    READ_INGREDIENT_CATEGORY: "READ_INGREDIENT_CATEGORY",
    MODIFY_INGREDIENT_CATEGORY: "MODIFY_INGREDIENT_CATEGORY",
    CREATE_INGREDIENT_CATEGORY: "CREATE_INGREDIENT_CATEGORY",
    DELETE_INGREDIENT_CATEGORY: "DELETE_INGREDIENT_CATEGORY",
    READ_INGREDIENT_CATEGORY_FOR_BRANCH: "READ_INGREDIENT_CATEGORY_FOR_BRANCH",
    READ_INGREDIENT: "READ_INGREDIENT",
    MODIFY_INGREDIENT: "MODIFY_INGREDIENT",
    CREATE_INGREDIENT: "CREATE_INGREDIENT",
    DELETE_INGREDIENT: "DELETE_INGREDIENT",
    READ_REQUESTED_INGREDIENT: "READ_REQUESTED_INGREDIENT",
    READ_GLOBAL_INGREDIENT_FOR_BRANCH: "READ_GLOBAL_INGREDIENT_FOR_BRANCH",
    CREATE_GLOBAL_INGREDIENT_FOR_BRANCH: "CREATE_GLOBAL_INGREDIENT_FOR_BRANCH",
    MODIFY_GLOBAL_INGREDIENT_FOR_BRANCH: "MODIFY_GLOBAL_INGREDIENT_FOR_BRANCH",
    READ_BRANCH_INGREDIENT: "READ_BRANCH_INGREDIENT",
    MODIFY_BRANCH_INGREDIENT: "MODIFY_BRANCH_INGREDIENT",
    CREATE_BRANCH_INGREDIENT: "CREATE_BRANCH_INGREDIENT",
    DELETE_BRANCH_INGREDIENT: "DELETE_BRANCH_INGREDIENT",
    READ_STOCK_TRANSACTION: "READ_STOCK_TRANSACTION",
    MODIFY_STOCK_TRANSACTION: "MODIFY_STOCK_TRANSACTION",
    CREATE_STOCK_TRANSACTION: "CREATE_STOCK_TRANSACTION",
    DELETE_STOCK_TRANSACTION: "DELETE_STOCK_TRANSACTION",
    READ_STOCK_SUMMARY: "READ_STOCK_SUMMARY",
    READ_EXPIRED_STOCK: "READ_EXPIRED_STOCK",
    READ_STOCK: "READ_STOCK",
    MODIFY_STOCK: "MODIFY_STOCK",
    CREATE_STOCK: "CREATE_STOCK",
    DELETE_STOCK: "DELETE_STOCK",
    READ_UNIT: "READ_UNIT",
    MODIFY_UNIT: "MODIFY_UNIT",
    CREATE_UNIT: "CREATE_UNIT",
    DELETE_UNIT: "DELETE_UNIT",
    READ_UNIT_CONVERSION: "READ_UNIT_CONVERSION",
    MODIFY_UNIT_CONVERSION: "MODIFY_UNIT_CONVERSION",
    CREATE_UNIT_CONVERSION: "CREATE_UNIT_CONVERSION",
    DELETE_UNIT_CONVERSION: "DELETE_UNIT_CONVERSION",
    READ_UNIT_FOR_BRANCH: "READ_UNIT_FOR_BRANCH",
    READ_SUPPLIER: "READ_SUPPLIER",
    MODIFY_SUPPLIER: "MODIFY_SUPPLIER",
    CREATE_SUPPLIER: "CREATE_SUPPLIER",
    DELETE_SUPPLIER: "DELETE_SUPPLIER",
    READ_AGENT: "READ_AGENT",
    MODIFY_AGENT: "MODIFY_AGENT",
    CREATE_AGENT: "CREATE_AGENT",
    DELETE_AGENT: "DELETE_AGENT",
    READ_BRANCH_CONFIG: "READ_BRANCH_CONFIG",
    MODIFY_BRANCH_CONFIG: "MODIFY_BRANCH_CONFIG",
    CREATE_BRANCH_CONFIG: "CREATE_BRANCH_CONFIG",
    DELETE_BRANCH_CONFIG: "DELETE_BRANCH_CONFIG",
    READ_BRANCH_USER_PAYMENT: "READ_BRANCH_USER_PAYMENT",
    CREATE_BRANCH_USER_PAYMENT: "CREATE_BRANCH_USER_PAYMENT",
    MODIFY_BRANCH_USER_PAYMENT: "MODIFY_BRANCH_USER_PAYMENT",
    CANCEL_BRANCH_USER_PAYMENT: "CANCEL_BRANCH_USER_PAYMENT",
    BRANCH_SUPPLIER: "BRANCH_SUPPLIER",
    READ_CATERING_PRODUCT: "READ_CATERING_PRODUCT",
    CREATE_CATERING_PRODUCT: "CREATE_CATERING_PRODUCT",
    MODIFY_CATERING_PRODUCT: "MODIFY_CATERING_PRODUCT",
    DELETE_CATERING_PRODUCT: "DELETE_CATERING_PRODUCT",
    READ_REQUESTED_CATERING_PRODUCT: "READ_REQUESTED_CATERING_PRODUCT",
    READ_GLOBAL_CATERING_PRODUCT_FOR_BRANCH: "READ_GLOBAL_CATERING_PRODUCT_FOR_BRANCH",
    READ_BRANCH_CATERING_PRODUCT: "READ_BRANCH_CATERING_PRODUCT",
    CREATE_BRANCH_CATERING_PRODUCT: "CREATE_BRANCH_CATERING_PRODUCT",
    MODIFY_BRANCH_CATERING_PRODUCT: "MODIFY_BRANCH_CATERING_PRODUCT",
    DELETE_BRANCH_CATERING_PRODUCT: "DELETE_BRANCH_CATERING_PRODUCT",
    READ_ORDER_KITCHEN_DISPLAY_DATA: "READ_ORDER_KITCHEN_DISPLAY_DATA",
    POS: "POS",
    FRANCHISE: "FRANCHISE",
    OWNER_FRANCHISE: "OWNER_FRANCHISE",
    READ_CATERING_ORDER: "READ_CATERING_ORDER",
    QR_CODE: "QR_CODE",
    RB_PRODUCT_MENU: "RB_PRODUCT_MENU",
    RB_INGREDIENT_MENU: "RB_INGREDIENT_MENU",
    RB_CATERING_MENU: "RB_CATERING_MENU",
    RB_CATERING_PRODUCT_MENU: "RB_CATERING_PRODUCT_MENU",
    RB_ADD_ON_MENU: "RB_ADD_ON_MENU",
    RB_DASHBOARD_MENU: "RB_DASHBOARD_MENU",
    RB_ANALYTICS_MENU: "RB_ANALYTICS_MENU",
    RB_CATEGORY_MENU: "RB_CATEGORY_MENU",
    RB_INGREDIENT_CATEGORY_MENU: "RB_INGREDIENT_CATEGORY_MENU",
    RB_CATERING_SECTION_MENU: "RB_CATERING_SECTION_MENU",

    RESTAURANT_DASHBOARD_MENU: "RESTAURANT_DASHBOARD_MENU",
    RESTAURANT_ANALYTICS_MENU: "RESTAURANT_ANALYTICS_MENU",
    RESTAURANT_CATEGORY_MENU: "RESTAURANT_CATEGORY_MENU",
    RESTAURANT_PRODUCT_MENU: "RESTAURANT_PRODUCT_MENU",
    RESTAURANT_REQUESTED_PRODUCT_MENU: "RESTAURANT_REQUESTED_PRODUCT_MENU",
    RESTAURANT_ADD_ON_MENU: "RESTAURANT_ADD_ON_MENU",
    RESTAURANT_REQUESTED_ADD_ON_MENU: "RESTAURANT_REQUESTED_ADD_ON_MENU",
    RESTAURANT_INGREDIENT_CATEGORY_MENU: "RESTAURANT_INGREDIENT_CATEGORY_MENU",
    RESTAURANT_INGREDIENT_MENU: "RESTAURANT_INGREDIENT_MENU",
    RESTAURANT_REQUESTED_INGREDIENT_MENU: "RESTAURANT_REQUESTED_INGREDIENT_MENU",
    RESTAURANT_CATERING_PRODUCT_MENU: "RESTAURANT_CATERING_PRODUCT_MENU",
    RESTAURANT_REQUESTED_CATERING_PRODUCT_MENU: "RESTAURANT_REQUESTED_CATERING_PRODUCT_MENU",
    RESTAURANT_CATERING_SECTION_MENU: "RESTAURANT_CATERING_SECTION_MENU",
    RESTAURANT_CATERING_MENU: "RESTAURANT_CATERING_MENU",
    RESTAURANT_REQUESTED_CATERING_MENU: "RESTAURANT_REQUESTED_CATERING_MENU",
    BRANCH_DASHBOARD_MENU: "BRANCH_DASHBOARD_MENU",
    BRANCH_ANALYTICS_MENU: "BRANCH_ANALYTICS_MENU",
    BRANCH_GLOBAL_INGREDIENT_MENU: "BRANCH_GLOBAL_INGREDIENT_MENU",
    BRANCH_INGREDIENT_MENU: "BRANCH_INGREDIENT_MENU",
    BRANCH_GLOBAL_PRODUCT_MENU: "BRANCH_GLOBAL_PRODUCT_MENU",
    BRANCH_GLOBAL_ADD_ON_MENU: "BRANCH_GLOBAL_ADD_ON_MENU",
    BRANCH_PRODUCT_MENU: "BRANCH_PRODUCT_MENU",
    BRANCH_ADD_ON_MENU: "BRANCH_ADD_ON_MENU",
    BRANCH_GLOBAL_CATERING_PRODUCT_MENU: "BRANCH_GLOBAL_CATERING_PRODUCT_MENU",
    BRANCH_GLOBAL_CATERING_MENU: "BRANCH_GLOBAL_CATERING_MENU",
    BRANCH_CATERING_MENU: "BRANCH_CATERING_MENU",
    BRANCH_CATERING_PRODUCT_MENU: "BRANCH_CATERING_PRODUCT_MENU",
    BRANCH_CATERING_ORDER_MENU: "BRANCH_CATERING_ORDER_MENU",

    READY_FOOD_STOCK_MENU: "READY_FOOD_STOCK_MENU",
}

export default Permission;